<template>
  <NuxtLayout name="main">
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
import {
  useProfileStore,
  useDocumentStore,
  useOrgStore,
  useClientStore,
  useResumeStore,
  useSearchStore
} from "./store";

await callOnce(async () => {
  await Promise.all([
    useProfileStore().fetchData(),
    useClientStore().fetchData(),
    useDocumentStore().fetchData(),
    useOrgStore().fetchData(),
    useSearchStore().fetchData(),
    useResumeStore().fetchData()
  ]);
});
</script>
