import _ from "lodash";
import {API} from "./util";
import type {
  EntityId,
  Search,
} from "~/common/types/types";

const apiRequest = new API<Search>("searches");

export const useSearchStore = defineStore("searchStore", {
  state: () => ({
    searches: [] as Search[],
  }),
  getters: {
    getSearch: (state) => (searchId: EntityId) => {
      const search = state.searches.find((x: Search) => x._id === searchId);
      if(!search) throw new Error(`search not found: ${searchId}`);
      return search;
    },
    getSearches: (state) => (orgId: EntityId) => state.searches.filter((x: Search) => x.clientId===orgId),
  },
  actions: {
    async addSearch(search: Search) {
      this.searches.push(search);
      await apiRequest.post(search);      
      await this.executeSearch(search);
    },
    async deleteSearch(search: Search) {
      this.searches = this.searches.filter((x: Search) => x._id !== search._id);
      await apiRequest.delete(search._id);
    },
    async updateSearch(item: Search) {
      this.searches = this.searches.map((x: Search) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
      await this.executeSearch(item);
    },
    async fetchData() {
      const searches = (await apiRequest.get());
      this.searches = searches;
    },
    async executeSearch(search: Search) {
      this.searches = this.searches.map((x: Search) => (x._id === search._id ? {...x, status: "pending", results: []} : x));
      const updatedSearch = await apiRequest.make("POST", "/api/searches/execute", search);
      this.searches = this.searches.map((x: Search) => (x._id === search._id ? updatedSearch : x));
    }
  },
});
