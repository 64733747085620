import _ from "lodash";
import {API} from "./util";
import type {
  EntityId,
  Client,
} from "~/common/types/types";

const apiRequest = new API<Client>("clients");

export const useClientStore = defineStore("clientStore", {
  state: () => ({
    clients: [] as Client[],
  }),
  getters: {
    getClient: (state) => (clientId: EntityId) => {
      const client = state.clients.find((x: Client) => x._id === clientId);
      if(!client) throw new Error(`client not found: ${clientId}`);
      return client;
    },
    getClients: (state) => (orgId: EntityId) => state.clients.filter((x: Client) => x.orgId===orgId),
  },
  actions: {
    async addClient(client: Client) {
      this.clients.push(client);
      await apiRequest.post(client);      
    },
    async deleteClient(client: Client) {
      this.clients = this.clients.filter((x: Client) => x._id !== client._id);
      await apiRequest.delete(client._id);
    },
    async updateClient(item: Client) {
      this.clients = this.clients.map((x: Client) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async fetchData() {
      const clients = (await apiRequest.get());
      this.clients = clients;
    },
  },
});
