import _ from "lodash";
import type {
  EntityId,
  Org,  
} from "~/common/types/types";
import { API } from "./util";

const apiRequest = new API<Org>("orgs");

export const useOrgStore = defineStore("orgStore", {
  state: () => ({
    orgs: [] as Org[],
  }),
  getters: {
    getOrg: (state) => (org_id: EntityId) => {
      const org = state.orgs.find((x: Org) => x._id === org_id);
      if(!org) throw new Error(`Org not found: ${org_id}`);
      return org;
    },
    getOrgs: (state) => state.orgs.map((x: Org) => ({ ...x })),
  },
  actions: {
    async addOrg(org: Org) {
      this.orgs.push(org);
      await apiRequest.post(org);      
    },
    async updateOrg(item: Org) {
      this.orgs = this.orgs.map((x: Org) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async deleteOrg(item: Org) {
      this.orgs = this.orgs.filter((x: Org) => x._id !== item._id);
      await apiRequest.delete(item._id);
    },
    async fetchData() {
      const orgs = (await apiRequest.get());
      this.orgs = orgs;
    },
  },
});
