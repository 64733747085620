import _ from "lodash";
import {API} from "./util";
import type {
  EntityId,
  Resume,
} from "~/common/types/types";
import { generateEntityId } from "~/common/types/helpers";

const apiRequest = new API<Resume>("resumes");

export const useResumeStore = defineStore("resumeStore", {
  state: () => ({
    resumes: [] as Resume[],
  }),
  getters: {
    getResume: (state) => (resumeId: EntityId) => {
      const resume = state.resumes.find((x: Resume) => x._id === resumeId);
      if(!resume) throw new Error(`resume not found: ${resumeId}`);
      return resume;
    },
    getResumes: (state) => (profileId: EntityId) => state.resumes.filter((x: Resume) => x.profileId===profileId),
  },
  actions: {
    async addResume(resume: Resume) {
      this.resumes.push(resume);
      const item = await apiRequest.post(resume);      
      this.resumes = this.resumes.map((x: Resume) =>
        x._id === resume._id ? item : x
      );
    },
    async deleteResume(resume: Resume) {
      this.resumes = this.resumes.filter((x: Resume) => x._id !== resume._id);
      await apiRequest.delete(resume._id);
    },
    async updateResume(item: Resume) {
      this.resumes = this.resumes.map((x: Resume) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async fetchData() {
      const resumes = (await apiRequest.get());
      this.resumes = resumes;
    },
  },
});
