import _ from "lodash";
import type { Document, EntityId } from "~/common/types/types";
import { API } from "./util";

const apiRequest = new API<Document>("documents");

export const useDocumentStore = defineStore("documentStore", {
  state: () => ({
    documents: [] as Document[],
  }),
  getters: {
    getDocument: (state) => (documentId: EntityId) => {
      const document = state.documents.find(
        (x: Document) => x._id === documentId
      );
      if (!document)
        throw new Error(`Document not found: ${documentId}`);
      return document;
    },
    getDocuments: (state) => (profileId: EntityId) => {
      const docs = state.documents.filter((x: Document) => x.profileId === profileId);
      return docs;
    }
  },
  actions: {
    async addDocument(item: Document) {
      this.documents.push(item);
      const created = await apiRequest.post(item);
      this.documents = this.documents.map((x: Document) =>
        x._id === created._id ? created : x
      );
    },
    async deleteDocument(item: Document) {
      this.documents = this.documents.filter(
        (x: Document) => x._id !== item._id
      );
      await apiRequest.delete(item._id);
    },
    async updateDocument(item: Document) {
      this.documents = this.documents.map((x: Document) =>
        x._id === item._id ? item : x
      );
      await apiRequest.put(item._id, item);
    },
    async fetchData() {
      this.documents = await apiRequest.get();
    },
    async importUrl(profileId: EntityId, url: string) {
      const item = {
        profileId,
        url
      }
      await apiRequest.make("POST", "/api/import", item);
      alert("Url submitted for import.")
    }
  },
});
