import type { EntityId } from "~/common/types/types";
import axios from 'axios';

export class API<TEntityType> {
  baseUrl: string;
  constructor(collection: string) {
    this.baseUrl = `/api/${collection}`;
  }

  async make(method: any, url: string, body?: any, query?: any): Promise<any> {
    try {
      const response = await $fetch(url, {
        method,
        body,
        query,
      });
      return response;
    } catch (err: any) {
      throw new Error(`${err.message} from ${method || "GET"} on ${url}.`);
    }
  }
  async poll(
    _id: EntityId,
    check: (item: TEntityType) => boolean
  ): Promise<TEntityType> {
    let item = await this.getOne(_id);
    while (!check(item)) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      item = await this.getOne(_id);
    }
    return item;
  }
  async get(): Promise<TEntityType[]> {
    return (await this.make("GET", this.baseUrl)) as TEntityType[];
  }
  async getOne(_id: EntityId): Promise<TEntityType> {
    const items = (await this.make(
      "GET",
      `${this.baseUrl}/${_id}`
    )) as TEntityType[];
    return items[0];
  }
  async post(data: TEntityType): Promise<TEntityType> {
    return (await this.make("POST", this.baseUrl, data)) as TEntityType;
  }
  async delete(id: EntityId, params?: any) {
    await this.make("DELETE", `${this.baseUrl}/${id}`, null, params);
  }
  async put(id: EntityId, changes: any, params?: any): Promise<TEntityType> {
    return (await this.make(
      "PUT",
      `${this.baseUrl}/${id}`,
      changes,
      params
    )) as TEntityType;
  }
}
