import { default as _91clientId_93pJZpejaPILMeta } from "/vercel/path0/pages/clients/[clientId].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91orgId_93EtT0VscyTvMeta } from "/vercel/path0/pages/orgs/[orgId].vue?macro=true";
import { default as _91profileId_93RjtU3VL2vXMeta } from "/vercel/path0/pages/profiles/[profileId].vue?macro=true";
import { default as _91resumeId_93e0dFtMvjCtMeta } from "/vercel/path0/pages/resumes/[resumeId].vue?macro=true";
import { default as _91searchId_93Tb6cKAaelTMeta } from "/vercel/path0/pages/searches/[searchId].vue?macro=true";
export default [
  {
    name: "clients-clientId",
    path: "/clients/:clientId()",
    component: () => import("/vercel/path0/pages/clients/[clientId].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orgs-orgId",
    path: "/orgs/:orgId()",
    component: () => import("/vercel/path0/pages/orgs/[orgId].vue").then(m => m.default || m)
  },
  {
    name: "profiles-profileId",
    path: "/profiles/:profileId()",
    component: () => import("/vercel/path0/pages/profiles/[profileId].vue").then(m => m.default || m)
  },
  {
    name: "resumes-resumeId",
    path: "/resumes/:resumeId()",
    component: () => import("/vercel/path0/pages/resumes/[resumeId].vue").then(m => m.default || m)
  },
  {
    name: "searches-searchId",
    path: "/searches/:searchId()",
    component: () => import("/vercel/path0/pages/searches/[searchId].vue").then(m => m.default || m)
  }
]