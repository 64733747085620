import _ from "lodash";
import {API} from "./util";
import type {
  EntityId,
  Profile,
  SocialProfile,
} from "~/common/types/types";

const apiRequest = new API<Profile>("profiles");

export const useProfileStore = defineStore("profileStore", {
  state: () => ({
    profiles: [] as Profile[],
  }),
  getters: {
    getProfile: (state) => (profileId: EntityId) => {
      const profile = state.profiles.find((x: Profile) => x._id === profileId);
      if(!profile) throw new Error(`profile not found: ${profileId}`);
      return profile;
    },
    getProfiles: (state) => (orgId: EntityId) => state.profiles.filter((x: Profile) => x.orgId===orgId),
  },
  actions: {
    async addProfile(profile: Profile) {
      this.profiles.push(profile);
      await apiRequest.post(profile);      
    },
    async deleteProfile(profile: Profile) {
      this.profiles = this.profiles.filter((x: Profile) => x._id !== profile._id);
      await apiRequest.delete(profile._id);
    },
    async updateProfile(item: Profile) {
      this.profiles = this.profiles.map((x: Profile) => (x._id === item._id ? item : x));
      await apiRequest.put(item._id, item);
    },
    async addSocialProfile(profileId: EntityId, social: SocialProfile) {
      const originalProfile = this.profiles.find((x: Profile) => x._id === profileId);
      if(!originalProfile) throw new Error(`profile not found: ${profileId}`);
      const updatedProfile = {
        ...originalProfile,
        socials: [...originalProfile.socials||[], social]
      };
      await this.updateProfile(updatedProfile);
    
    },
    async removeSocialProfile(profileId: EntityId, social: SocialProfile) {
      const originalProfile = this.profiles.find((x: Profile) => x._id === profileId);
      if(!originalProfile) throw new Error(`profile not found: ${profileId}`);
      const updatedProfile = {
        ...originalProfile,
        socials: originalProfile.socials.filter(x=> !_.isEqual(x, social))
      };
      await this.updateProfile(updatedProfile);
    },
    async fetchData() {
      const profiles = (await apiRequest.get());
      this.profiles = profiles;
    },
    async importUrl(profileId: EntityId, url: string) {
      const item = {
        profileId,
        url
      }
      await apiRequest.make("POST", "/api/import", item);
      alert("Url submitted for import.")
    
    }
  },
});
